<template>
  <div>
    <CCard>
      <CCardHeader>Update Password</CCardHeader>
      <CCardBody>
        <CRow>
          <CCol md="12" lg="6">
            <CForm @submit="checkForm">
              <CInput 
                v-model="cpassword"
                type="password" 
                placeholder="Current password"
                required>
                <template #prepend-content><CIcon name="cil-shield-alt"/></template>
              </CInput>
              <CInput 
                v-model="password"
                type="password" 
                placeholder="New password"
                required>
                <template #prepend-content><CIcon name="cil-shield-alt"/></template>
              </CInput>
              <CInput 
                v-model="password1"
                type="password" 
                placeholder="Confirm new password"
                required>
                <template #prepend-content><CIcon name="cil-shield-alt"/></template>
              </CInput>
              <div class="form-group form-actions">
                <CButton block type="submit" color="success">Update Password</CButton>
              </div>
            </CForm>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import apiAccount from '@/api/account'

export default {
  name: 'UpdatePassword',
  components: {
  },
  data () {
    return {
      cpassword: "",
      password: "",
      password1: ""
    }
  },
  methods: {
    validator (val) {
      return val ? val.length >= 4 : false
    },
    checkForm: function (e) {
      
      e.preventDefault();
      
      apiAccount.updatePassword(this.cpassword, this.password, this.password1).then(response => {
        if (response.data.status === 'successful') {
          alert("login successful");
        } else {
          alert("login failed");
        }
      }).catch((err) => {
          alert("login error");
          console.log(err);
      })

    }
  }
}
</script>
