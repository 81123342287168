import request from '@/api/axios-request'

const apiAccount = {

  login(username, password) {
    return request({
      url: '/ad/account/login',
      method: 'post',
      data: {
        username,
        password
      }
    })
  },

  logout() {
    return request({
      url: '/ad/account/logout',
      method: 'post'
    })
  },

  updatePassword(cpassword, password, password1) {
    return request({
      url: '/ad/account/updatePassword',
      method: 'post',
      data: {
        cpassword,
        password,
        password1
      }
    })
  }

}

export default apiAccount